<template>
<div v-if="isLoaded.global" class="sub-page" id="top">
  <header :style="{backgroundImage: 'url(' + storageUrl + global.backgroundMateriel.path + ')'}">
      <h1>{{  global.titleMaterial }}</h1>
      <p>{{  global.subTitleMateriel }}</p>
      <svg width="100%" height="auto" viewBox="0 0 1920 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g id="Plan-de-travail1" transform="matrix(1,0,0,1,0.1,-33.7869)">
            <path d="M0,33.787C424.352,80.771 614.774,74.172 729.06,74.172C843.345,74.172 1217.24,37.46 1469.94,40C1722.64,42.54 1920,57.399 1920,57.399L1920,80L0,80C0,80 -0.225,64.263 0,33.787Z" style="fill:rgb(46,46,46);"/>
        </g>
    </svg>
  </header>
  <div class="materiel secondary-layer wrap1140">
    <div>
      <h2>Materiel opérationnel</h2>
      <p>Matériel que nous possédons et que nous louons.</p>
      <div class="materiel-list">
        <div v-for="matOp in materiel.op.entries" :key="matOp.id">
          <img :src="storageUrl + matOp.img.path" :alt="matOp.img.descritpion">
          <div>
            <h3>{{ matOp.name }}</h3>
            <p>{{ matOp.desc }}</p>
          </div>
          <div v-if="matOp.isInRent">
            <a target="_blank" :href="matOp.rentUrl" class="btn">Louer</a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2>Materiel connu</h2>
      <p>Materiel déjà utilisé par le passé sur différents projets.</p>
      <div class="materiel-list">
        <div v-for="matUs in materiel.used.entries" :key="matUs.id">
          <img :src="storageUrl + matUs.img.path" :alt="matUs.img.descritpion">
          <div>
            <h3>{{ matUs.name }}</h3>
            <p>{{ matUs.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      storageUrl: this.$store.state.storageUrl,
    }
  },
  beforeMount () {
    this.getMaterielOp();
    this.getMaterielUsed();
  },
  mounted () {
    
  },
  methods: {
    ...mapActions(['getMaterielOp']),
    ...mapActions(['getMaterielUsed']),
  },
  computed: {
    ...mapState(['materiel', 'isLoaded', 'global'])
  },
}
</script>


<style lang="scss" scoped>
    @import './Secondary.scss';
</style>
